// IE 10 fixes
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}

html,
body {
  min-height:100%;
  font-family:$Larsseit;
  font-feature-settings: 'tnum'on, 'lnum'on, 'titl'on;
  font-size:$body-font-size;
  text-rendering: optimizeLegibility;
  line-height:$body-line-height;
  color:var(--n500);
}
body {
  background-color:var(--n0);
  height:100vh;
  display:flex; 
  flex-direction:column; 
}
.p-component {
  font-family:$Larsseit;
  font-feature-settings: 'tnum'on, 'lnum'on, 'titl'on;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
  image-rendering: -moz-crisp-edges;
  image-rendering:   -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
a:focus {
  box-shadow: none !important;
}
.time_edit::before,
.time_edit::after {
  display: table;
  content: ' ';
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-order: 1;
      order: 1;
}
.time_edit::after {
  clear: both;
}
.pagination {
  margin-top: 1.5625rem;

  li {
    margin: 0;

    &.disabled,
    &.current,
    &.ellipsis,
    a {
      font-size: 18px;
      font-weight: 600;
      color: var(--pale-purple);
      padding: .3125rem .9375rem;

      &:hover {
        background: #F0F0F0;
        color: var(--pale-purple);
      }
    }

    &.current {
      background: var(--p200);
      color: var(--n0);
      margin: 0 .625rem;

      &:hover {
        background: var(--p200);
        color: var(--n0);
      }
    }

    &.disabled,
    &.disabled:hover {
      background: none;
      color: #ccc;
    }

    &.ellipsis {
      overflow: unset;
      padding: 0;

      &:hover {
        background: none;
      }

      &:after {
        color: var(--pale-purple);
      }
    }
  }
}
.grid-container {
  &.full {
    padding:0;
  }
}
.grid-padding-x {
  > .cell {
    padding-left:1.25rem;
    padding-right:1.25rem;

    @media (min-width: $width-md) {
      padding-left:1.25rem;
      padding-right:1.25rem;
    }
  }
}
.sd_container {
  padding:0 .9375rem;
  max-width:90.625rem;
  margin:0 auto;

  @media (min-width: $width-sm) {
    padding:0 1.25rem;
  }
}
.sd_container_lg {
  padding:0 .9375rem;
  max-width:60.3125rem;
  margin:0 auto;
}
.npb {
  padding-bottom: 0 !important;
}
.nmb {
  margin-bottom:0 important;
}
.main-wrapper {
  min-height: 100vh;
  background-color:var(--n20);

  &.bg-white {
    background: var(--n0);
  }
}
.p-button,
.p-inputtext {
  &:focus {
    box-shadow: none !important;
  }
}
app-main,
.flex-container {
  display:flex; 
  flex-direction:column;
  min-height: 100vh;
}
.body-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;

  #Login {
    flex: 1 0 auto;
  }
  #Footer {
    flex-shrink: 0;
    z-index:0;
  }
}