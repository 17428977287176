button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
.btn-sd {
  font-size: 1.5rem;
  padding: 0 1.25rem;
  height: 3.25rem;
  line-height: 3.25rem;
  border-radius: 0.25rem;
  font-weight: $medium;
  background: none;
  margin: 0;
  color: var(--n0);
  cursor: pointer;
  border: 0 none;
  display: inline-block;
  

  .p-button-icon-left {
    margin-right: 0.4375rem;
  }
  .p-button-icon-right {
    margin-left: 0.4375rem;
  }
  &.p-button-icon-only {
    width: auto;
    padding: 0 0.5625rem;

    .p-button-label {
      display: none;
    }
  }
}
.btn-green {
  background-color: var(--g300);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--g400);
    color: var(--n0);
  }
  &:enabled {
    &:hover,
    &:active,
    &:focus {
      background-color: var(--g400);
      color: var(--n0);
    }
  }
  &:disabled {
    background-color: var(--g60);
  }
}
.btn-blue {
  background-color: var(--b600);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--b700);
    color: var(--n0);
  }
  &:enabled {
    &:hover,
    &:active,
    &:focus {
      background-color: var(--b700);
      color: var(--n0);
    }
  }
  &:disabled {
    background-color: var(--b50);
  }
}
.btn-red {
  background-color: var(--o200);

  &.btn-no-disable {
    &.p-disabled,
    &:disabled {
      background-color: var(--o200);
      opacity: 1 !important;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--o300);
  }
  &:enabled {
    &:hover,
    &:active,
    &:focus {
      background-color: var(--o300);
    }
  }
  &:disabled {
    background-color: var(--o50);
  }
}
.btn-ghost {
  background: var(--n0);
  color: var(--n500);
  border: 0.125rem solid var(--n0);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  line-height: 3.125rem;

  &:hover,
  &:active,
  &:focus {
    color: var(--n500);
    background-color: var(--n0);
  }
  &:enabled {
    &:hover,
    &:active,
    &:focus {
      color: var(--n500);
      background-color: var(--n0);
    }
  }

  &.btn-ghost-green {
    border: 0.125rem solid var(--g300);
    color: var(--g300);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--g300);
      border-color: var(--g300);
      color: var(--n0);
    }
    &:enabled {
      &:hover,
      &:active,
      &:focus {
        background-color: var(--g300);
        border-color: var(--g300);
        color: var(--n0);
      }
    }
  }
  &.btn-sm {
    line-height: 1.875rem;
  }
  &.btn-md {
    line-height: 2.375rem;
  }
  &.btn-lg {
    line-height: 4.375rem;
  }
  &.btn-dd {
    padding: 0;

    .p-dropdown-label {
      padding: 0 0 0 0.625rem;
      font-size: 0.75rem;
    }
    .p-dropdown-trigger {
      width: 1.25rem;
    }
    .p-dropdown-trigger-icon {
      font-size: 0.5rem;
    }
  }
}
.btn-sm {
  font-size: 0.75rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  height: 2rem;

  .p-button-icon {
    font-size: 0.5rem;
  }
  &.p-button-icon-only {
    .p-button-icon {
      &:before {
        font-size: 0.8125rem;
      }
    }
  }
}
.btn-md {
  font-size: 1rem;
  padding: 0 1rem;
  line-height: 2.5rem;
  height: 2.5rem;
  font-weight: $bold;

  .p-button-icon {
    font-size: 1rem;
  }
}
.btn-lg {
  font-size: 1.5rem;
  padding: 0 2rem;
  height: 4.5rem;
  line-height: 4.5rem;
}
.btn-block {
  display: block;
  text-align: center;
  width: 100%;
}
.sd-switch {
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: var(--p200);
    }
    &:not(.p-disabled) {
      &:hover {
        .p-inputswitch-slider {
          background: var(--p200);
        }
      }
    }
  }
}
.btn-avatar {
  padding: 0.25rem;
  border: .125rem solid transparent;
  background: none;
  border-radius: 50%;

  &:hover,
  &:active,
  &:focus {
    background: none;
    border-color: var(--y60);
  }
  &:enabled {
    &:hover,
    &:active,
    &:focus {
      background: none;
      border-color: var(--y60);
      box-shadow: none;
    }
  }
  &.btn-xl {
    width: 4.75rem;
    height: 4.75rem;
  }
}
.p-togglebutton {
  &.p-button {
    border: 0 none;
    padding: 0 0.3125rem;
    font-size: 1rem;
    width: auto;

    &:hover {
      background: none !important;
    }
    span {
      color: var(--n500) !important;
    }
    &.p-highlight {
      background: none;
      color: var(--n500) !important;

      span,
      &:hover {
        color: var(--n500) !important;
        background: none !important;
      }
    }
  }
}
.btn-select {
  &.p-buttonset {
    .p-button {
      padding: 0;
      border: 0 none;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem !important;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      background: var(--n0);
      color: var(--n100);
      text-align: center;
      font-size: 0.875rem;
      display: inline-block;

      &:last-child {
        margin-left: 0.5rem !important;
      }
      &.p-highlight {
        background: var(--b400);
        color: var(--n0);
      }

      i {
        &:before {
          width: auto;
        }
      }
    }
  }
}
.with-arrow {
  position:relative;
  padding-right: 1.625rem;
  padding-left: .3125rem;

  &:before {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: .4375rem;
  }
}
