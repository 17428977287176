.sd-card {
  .p-card {
    background: var(--n0);
    box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    color: var(--n600);

    .card-title {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
      }
      &-btn {
        flex: 0 0 auto;
      }
    }
    .p-card-body {
      padding: 1rem;

      @media (min-width: $width-sm) {
        padding: 1.875rem 2rem;
      }
      .p-card-content {
        padding: 0;

        .ws-section {
          padding-bottom: 1.625rem;

          &.flex {
            display: flex;
            align-items: flex-start;
          }
          &.space-between {
            justify-content: space-between;
          }
          &.border-bottom {
            border-bottom: 0.0625rem solid var(--n40);
          }
          &:last-child {
            padding-bottom: 0.3125rem;
          }
          .ws-name {
            margin-right: 1.25rem;
          }
          h6 {
            text-transform: none;
            margin: 0;
            color: var(--n500);
            font-weight: $medium;
          }
          h5 {
            margin: 0;
          }
          + .ws-section {
            padding-top: 1.625rem;
          }
          .ws-icon {
            .p-avatar {
              width: 3.75rem;
              height: 3.75rem;
            }
          }
          .ws-icon-text {
            margin-left: 0.8125rem;
            max-width: 26.25rem;
            margin-right: 1.25rem;

            h3 {
              margin-bottom: 0.1875rem;
            }
            p {
              margin: 0;
              font-size: 0.6875rem;
            }
          }
          .ws-icon-set {
            flex-shrink: 0;
            margin-left: auto;
          }
          .ws-name-block {
            &:not(:last-child) {
              margin-bottom: 1.25rem;
            }
            h5 {
              font-weight: $medium;
              margin-bottom: .125rem;
            }
            p {
              margin:0;
            }
          }
          .ws-row {
            max-width: 23.5rem;
            display: inline-flex;
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 1.25rem;
            }
            .ws-name-block {
              flex: 1 1 50%;
              margin-bottom:0;
            }
          }
          &.billing-details {
            position:relative;

            .ws-name {
              margin-right:0;

              &-block {
                h6 {
                  font-size: .8125rem;
                  line-height: 1.125rem;
                  margin-bottom: .1875rem;
                }
                p {
                  font-weight: $medium;
                  color: var(--n500);
                }
                &:first-child {
                  padding-right: 3.125rem;
                }
              }
            }
            .btn-sd {
              position:absolute;
              right:0;
              top:0;
            }
          }
        }
        .ma-section {
          @media (min-width: $width-sm) {
            display: inline-flex;
            width: 100%;
          }
          .ma-details {
            display: inline-flex;
            width: 100%;

            @media (min-width: $width-sm) {
              margin-bottom: 0;
            }
            .sd-avatar {
              width: 5rem;
              height: 5rem;
              margin-right: 1rem;

              @media (min-width: $width-sm) {
                width: 7.5rem;
                height: 7.5rem;
                margin-right: 1.5rem;
              }
            }
            .ma-details-text {
              flex: 1 1 auto;

              &:not(:first-child) {
                @media (min-width: $width-sm) {
                  margin-top: 0.5rem;
                }
              }
              h3 {
                font-size: 1.25rem;
                line-height: 1.5rem;
                margin: 0;
              }
              .ma-position {
                font-size: 1rem;
                color: var(--n500);
                margin-bottom: 1rem;
              }
              .ma-location {
                font-size: 0.8125rem;
                font-weight: $medium;
                color: var(--n500);
                margin: 0;
              }
              .ma-time {
                font-size: 0.6875rem;
                color: var(--n200);
                margin: 0;
              }
              h6 {
                font-weight: $medium;
                text-transform: none;
                margin: 0;
                color: var(--n500);
              }
              .ma-email,
              .ma-password {
                margin: 0;
                font-weight: $bold;
                color: var(--n500);
              }
            }
          }
          .ma-btn {
            margin-top: 1rem;

            @media (min-width: $width-sm) {
              flex: 0 0 auto;
              margin-left: auto;
              margin-top: 0;
            }
          }
        }
        .display-section {
          padding-bottom: 1.25rem;

          + .display-section {
            padding-top: 1.25rem;
          }
          &:last-child {
            padding-bottom: 0;
          }
          &.border-bottom {
            border-bottom: 0.0625rem solid var(--n40);
          }
          h6 {
            text-transform: none;
            font-weight: $medium;
            margin: 0;
          }
          h5 {
            margin: 0;
          }
          p {
            font-size: 0.6875rem;
            line-height: 0.9375rem;
            margin: 0;
          }
        }
      }
    }
  }
  &.project-overview-people {
    h4 {
      margin-bottom: 0;
    }
    .avatar-container {
      margin-top: 1.5625rem;
    }
    .project-users {
      margin-bottom: 0.9375rem;

      @media (min-width: $width-sm) {
        margin-left: -0.25rem;
        margin-right: -0.25rem;
      }
    }
    .btn-avatar {
      @media (min-width: $width-sm) {
        margin-left: 0.125rem;
        margin-right: 0.125rem;
      }
      .sd-avatar {
        width: 4rem;
        height: 4rem;
        max-width: none;
      }
    }
    .avatar-subtitle {
      display: block;
      font-size: 0.8125rem;
      color: var(--n500);
      margin-top: 0.3125rem;
    }
    .project-user-invite {
      padding-top: 1.875rem;
      position: relative;

      &:before {
        content: "";
        height: 0.0625rem;
        background-color: var(--n40);
        position: absolute;
        top: 0;
        left: -1rem;
        right: -1rem;

        @media (min-width: $width-sm) {
          left: -2rem;
          right: -2rem;
        }
      }
      p {
        font-size: 0.8125rem;
      }
    }
    .project-invite-icon {
      width: 5rem;
      margin-left: 1.25rem;
      flex: 1 0 auto;
    }
    .op-sd {
      .user-block {
        width: 100%;
      }
      .user-name {
        flex: 1 1 auto;
      }
    }
  }
  &.project-task {
    h4 {
      margin-bottom: 0.75rem;
    }
    .sub-title {
      font-size: 0.8125rem;
      margin-bottom: 0.625rem;
      line-height: 1.125rem;
    }
  }
  &.history-card {
    .p-card-body {
      @media (min-width: $width-lg) {
        padding: 1.625rem;
      }
    }
  }
  &.plan-card {
    .p-card {
      .p-card-body {
        .card-title {
          h3 {
            span {
              display: inline-block;
              margin-left: .625rem;

              strong {
                color: var(--b600);
                font-weight: $regular;
              }
            }
          }
        }
        .ws-section {
          padding-bottom: 1.25rem;

          p {
            color: var(--n500);
            margin-bottom: 0.5rem;
          }
          ul {
            margin: 0;

            @media (min-width: $width-sm) {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              margin-left: -1rem;
              margin-right: -1rem;
            }
            li {
              padding-left: 1.25rem;
              position: relative;
              margin-bottom: 0.25rem;
              color: var(--n500);

              @media (min-width: $width-sm) {
                flex: 0 1 50%;
                padding: 0 1rem 0 2.25rem;
              }
              @media (min-width: $width-lg) {
                flex: 0 1 33.3334%;
              }
              i {
                position: absolute;
                left: 0;
                top: 0.3125rem;
                width: 0.75rem;
                height: 0.75rem;
                font-size: 0.4375rem;
                line-height: 0.75rem;
                border-radius: 50%;
                background: var(--g300);
                color: var(--n0);
                text-align: center;

                @media (min-width: $width-sm) {
                  left: 1rem;
                }
                &:before {
                  margin: 0;
                  width: auto;
                }
              }
              &.feature-pro {
                i {
                  background: var(--b600);
                }
              }
            }
          }
        } 
      }
    }
    &.plan-current {
      .p-card {
        .card-title {
          display: block;

          h2 {
            margin: 0;
            font-weight: $medium;
            font-size: 3rem;

            @media (min-width: $width-sm) {
              font-size: 4rem;
              line-height: 5.625rem;
              height: 5.625rem;
            }
            span {
              display: inline-block;
              font-size: 1.5rem;
              font-weight: $regular;
              line-height: normal;
              color: var(--b600);
              margin-left: 1rem;

              @media (min-width: $width-sm) {
                margin-left: 1.625rem;
                font-size: 2rem;
              }
            }
          }
        }
      }
      &.plan-pro {
        .p-card {
          .card-title {
            h2 {
              span {
                color: var(--n600);
                font-size: 1rem;
                line-height:1.25rem;
                vertical-align: top;
                margin-top: .875rem;

                strong {
                  display: block;
                  font-size: 1.5rem;
                  font-weight: $regular;
                  color: var(--b600);
                }
                small {
                  display: block;
                  color: var(--n500);
                }
              }
            }
          }
        }
      }
    }
    &.plan-pro {
      .p-card {
        .card-title {
          justify-content: flex-start;
          align-items: flex-start;

          h3 {
            margin-right: 1rem;
          }
          h4 {
            margin: 0.3125rem 0 0 0;
            font-size: 1rem;
            line-height: 1.5rem;
            color: var(--n600);
            font-weight: $medium;

            strong {
              font-size: 1.25rem;
              color: var(--b600);
              font-weight: $regular;
            }
            small {
              display: block;
              color: var(--n500);
              font-size: 0.8125rem;
              font-weight: $regular;
            }
          }
        }
      }
    }
  }
}
.payment-method {
  .p-card {
    .card-title {
      flex-wrap: wrap;

      @media (min-width: $width-sm) {
        flex-wrap: nowrap;
      }
      h3 {
        flex: 1 1 100%;

        @media (min-width: $width-sm) {
          flex: 1 1 auto;
        }
      }
      p {
        margin-top: 1rem;
        margin-bottom: 0;

        @media (min-width: $width-sm) {
          margin-top:0;
        }
      }
    }
  }
}
.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @media (min-width: $width-md) {
    margin-left: -1.375rem;
    margin-right: -1.375rem;
  }
  > p,
  .card-inner {
    flex: 1 1 auto;
    max-width: 17.25rem;
    padding-left: .5rem;
    padding-right: .5rem;

    @media (min-width: $width-md) {
      padding-left: 1.375rem;
      padding-right: 1.375rem;
      max-width: 19rem;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
.invoice-list {
  .invoice-summary {
    position:relative;

    @media (min-width: $width-md) {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .invoice-date-title {
      h6 {
        font-size: .8125rem;
        line-height: normal;
        font-weight: $medium;
        color: var(--n500);
        margin-bottom: .25rem;
      }
      p {
        font-size: .8125rem;
        line-height: normal;
        color: var(--n500);
        margin:0;
      }
    }
    .invoice-price-cta {
      position:absolute;
      top:0;
      right:0;

      @media (min-width: $width-md) {
        position:static;
      }
      h6 {
        margin:0 1rem 0 0;
        font-size: .8125rem;
        line-height: normal;
        color: var(--n500);
        font-weight: $medium;
        display: inline-block;
        vertical-align: top;
        
        @media (min-width: $width-md) {
          display: block;
          text-align: right;
          margin: 0 0 .25rem;
        }
      }
      a {
        display: inline-block;
        vertical-align: top;
        font-size: .8125rem;
        line-height: normal;
        font-weight: $bold;
        color: var(--b400);
      }
    }
  }
}