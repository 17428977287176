/*
This file is used to contain all component imports.
*/

//Import Component files
@import "login";
@import "buttons";
@import "badge";
@import "menu";
@import "tabmenu";
@import "dropdown";
@import "search";
@import "table";
@import "alert";
@import "card";
@import "card-listing";
@import "dialog";
@import "form";
@import "chips";
@import "avatar";
@import "filter";
@import "tag";
@import "box";
@import "tooltips";
@import "timer";
@import "timer-report";
@import "history";
@import "pagination";
@import "calendar";
@import "colorpicker";
@import "onboarding";
@import "focus-hour";
@import "timebar";
