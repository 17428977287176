.panel-calendar {
  &.p-overlaypanel {
    width: 100%;
    height: auto;
    margin: 0;
    max-width: 25rem;
    border-radius: 0.3125rem;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    max-height: 90%;
    overflow: auto;

    @media (min-width: $width-md) {
      max-width: 43.75rem;
    }
    @media (min-width: $width-lg) {
      max-width: 56.25rem;
    }
    .p-overlaypanel-content {
      @media (min-width: $width-lg) {
        display: flex;
      }
      > div {
        &:last-child {
          @media (min-width: $width-lg) {
            flex: 1 auto;
            border-right: 0.0625rem solid var(--n40);
          }
        }
      }
    }
    .p-datepicker-header {
      border: 0.0625rem solid var(--n40);
      padding-left: 2.5rem;
      padding-right: 2.5rem;

      .p-datepicker-prev {
        margin-left: -1.875rem;
      }
      .p-datepicker-next {
        margin-right: -1.875rem;
      }
    }
    .p-calendar {
      width: 100%;
    }
    &:after,
    &:before {
      display: none;
    }
    .p-datepicker-inline {
      display: block;
      border: 0 none;
      padding: 0;
    }
    .cal-dd {
      display: none;

      @media (min-width: $width-lg) {
        display: block;
        height: 100%;
      }
      .p-menu {
        height: 100%;
        border-radius: 0 0 0.1875rem 0.1875rem;
      }
    }
    .p-datepicker-multiple-month {
      .p-datepicker-group-container {
        @media (max-width: $max-width-sm) {
          display: block;
        }
        @media (min-width: $width-md) {
          width: 100%;
        }
      }
      .p-datepicker-group {
        @media (max-width: $max-width-sm) {
          border: 0 none;
          padding: 0;
          width: 100%;
          height: 20rem;
        }
        @media (min-width: $width-md) {
          width: 50%;
        }
      }
    }
    table {
      th,
      td {
        @media (min-width: $width-md) {
          padding: 0;
        }
      }
      td {
        .p-highlight {
          background: var(--b400);
          color: var(--n0);
        }
      }
    }
    .button-block {
      text-align: center;
      display: block;
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      border-top: 0.0625rem solid var(--n40);
      margin-top: 1rem;

      @media (min-width: $width-md) {
        margin-top: 0;
        margin-left: -1rem;
        margin-right: -1rem;
      }
      @media (min-width: $width-lg) {
        margin: 0;
        border-bottom: 0.0625rem solid var(--n40);
        border-right: 0.0625rem solid var(--n40);
      }
      p {
        font-size: 1.25rem;
      }
      .btn-sd {
        margin: 0 0.625rem;
      }
    }
  }
}
.p-datepicker table td.p-datepicker-today > span {
  background: none;
}