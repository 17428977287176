@font-face {
  font-family: "torobravo";
  src: url("/assets/fonts/torobravo/torobravo.27464631.eot");
  src: url("/assets/fonts/torobravo/torobravo.27464631.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/torobravo/torobravo.27464631.woff2") format("woff2"),
    url("/assets/fonts/torobravo/torobravo.27464631.woff") format("woff"),
    url("/assets/fonts/torobravo/torobravo.27464631.ttf") format("truetype"),
    url("/assets/fonts/torobravo/torobravo.27464631.svg#torobravo") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'torobravo';
    src: url('../fonts/torobravo.27464631.svg#torobravo') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "torobravo";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-manual:before { content: '\e800'; } /* '' */
.icon-dots:before { content: '\e801'; } /* '' */
.icon-play:before { content: '\e802'; } /* '' */
.icon-done:before { content: '\e803'; } /* '' */
.icon-close:before { content: '\e804'; } /* '' */
.icon-search:before { content: '\e805'; } /* '' */
.icon-select-bold:before { content: '\e806'; } /* '' */
.icon-long-arrow-right:before { content: '\e807'; } /* '' */
.icon-left-open:before { content: '\e808'; } /* '' */
.icon-right-open:before { content: '\e809'; } /* '' */
.icon-down-open-1:before { content: '\e80a'; } /* '' */
.icon-sort-up:before { content: '\e80b'; } /* '' */
.icon-sort-down:before { content: '\e80c'; } /* '' */
.icon-print:before { content: '\e80d'; } /* '' */
.icon-plus:before { content: '\e80e'; } /* '' */
.icon-settings:before { content: '\e80f'; } /* '' */
.icon-content-copy:before { content: '\e810'; } /* '' */
.icon-billable:before { content: '\e811'; } /* '' */
.icon-unbillable:before { content: '\e812'; } /* '' */
.icon-plus-solid:before { content: '\e813'; } /* '' */
.icon-sync-alert:before { content: '\e814'; } /* '' */
.icon-eye:before { content: '\e815'; } /* '' */
.icon-select:before { content: '\e816'; } /* '' */
.icon-timer:before { content: '\e817'; } /* '' */
.icon-calendar:before { content: '\e818'; } /* '' */
.icon-arrow-right:before { content: '\e819'; } /* '' */
.icon-link:before { content: '\e81a'; } /* '' */
.icon-analysis:before { content: '\e81b'; } /* '' */
.icon-comment-lines-solid:before { content: '\e81c'; } /* '' */
.icon-comment-plus:before { content: '\e81d'; } /* '' */
.icon-list:before { content: '\e81e'; } /* '' */
.icon-timebar:before { content: '\e81f'; } /* '' */
.icon-comment-lines:before { content: '\e820'; } /* '' */
.icon-check:before { content: '\e821'; } /* '' */
.icon-chevron-down:before { content: '\e822'; } /* '' */
.icon-chevron-up:before { content: '\e823'; } /* '' */
.icon-exclamation-triangle:before { content: '\e824'; } /* '' */
.icon-menu:before { content: '\e825'; } /* '' */
.icon-share:before { content: '\e826'; } /* '' */
.icon-export:before { content: '\e827'; } /* '' */
.icon-add-user:before { content: '\e836'; } /* '' */
.icon-cc-amex:before { content: '\e84c'; } /* '' */
.icon-cc-diners-club:before { content: '\e84d'; } /* '' */
.icon-cc-discover:before { content: '\e84e'; } /* '' */
.icon-cc-jcb:before { content: '\e84f'; } /* '' */
.icon-cc-mastercard:before { content: '\e850'; } /* '' */
.icon-cc-visa:before { content: '\e851'; } /* '' */
.icon-timer-auto:before { content: '\e854'; } /* '' */
.icon-headset:before { content: '\e858'; } /* '' */
.icon-arrow-up:before { content: '\e859'; } /* '' */
.icon-times-circle:before { content: '\e862'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-question-circle:before { content: '\f29c'; } /* '' */