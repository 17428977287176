.notice {
	padding-top: 1.5rem;
}
.one-liner-summary {
	padding-top: 1.5rem;

	h2 {
		color: var(--n500);
		font-weight: $regular;
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.375rem;

		.user-avatar {
			top: -7px;
			position: relative;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			display: inline-block;
			vertical-align: text-bottom;
		}
	}
	p {
		font-size: 1.25rem;
		line-height: 1.625rem;
		color: var(--n500);
		font-weight: $regular;

		strong {
			font-weight: $bold;
		}
	}
}
.mini-widget-wrapper {
	padding-top: 1.25rem;
	flex-wrap: wrap;
	margin-bottom: 0.5rem;

	@media (min-width: 40rem) {
		flex-wrap: wrap;
	}
	@media (min-width: 62rem) {
		max-width: 755px;
		justify-content: center;
		margin: 0 auto 0.5rem;
	}

	.sd-card {
		flex: 1 0 auto;
		margin-bottom: 0.5rem;

		@media (min-width: 40rem) {
			flex: 31.333%;
		}
		&:not(:last-child) {
			@media (min-width: 40rem) {
				margin-right: 0.9375rem;
			}
		}
		.p-card {
			height: 100%;
		}

		img {
			width: 2.5rem;
		}
		.card-content {
			display: flex;
			align-items: center;

			.wrapper {
				padding-left: 1rem;
			}

			h6 {
				font-weight: $medium;
				color: var(--n500);
				font-size: 1.5rem;
				line-height: 1.8125rem;
				text-transform: lowercase;
				margin-bottom: 0.25rem;
			}
			p {
				color: var(--n200);
				font-weight: $regular;
				font-size: 0.8125rem;
				line-height: 1.125rem;
				margin-bottom: 0;
			}
		}
	}
}
.widgets {
	.sd-card {
		.p-card {
			margin-bottom: 1rem;
		}
		.sub-title {
			color: var(--n200);
			font-size: 0.8125rem;
			line-height: 1.125rem;
			font-weight: $regular;
		}
		.project-card {
			.sub-title {
				margin-bottom: 1.5rem;
			}
			.project-item {
				margin-bottom: 1.625rem;

				@media (min-width: 48rem) {
					display: flex;
				}

				.left-col {
					@media (min-width: 48rem) {
						margin-right: 0.5rem;
						flex: 1;
					}
				}
				.right-col {
					@media (min-width: 48rem) {
						flex: 2;
						display: flex;
					}
					p {
						font-weight: $regular;

						@media (min-width: 48rem) {
							margin-left: 0.25rem;
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
				p {
					color: var(--n500);
					font-weight: $medium;
					font-size: 0.8125rem;
					line-height: 1.125rem;
					margin-bottom: 0.5rem;

					@media (min-width: 48rem) {
						margin-bottom: 0;
					}
				}
			}
		}
		.task-card {
			.sd-table {
				margin-bottom: 0;

				p {
					color: var(--n500);

					small {
						display: block;
					}
					a {
						font-size: 1rem;
						line-height: 1.5rem;
						color: var(--n500);
						cursor: pointer;
					}
					small {
						color: var(--n200);
						font-size: 0.8125rem;
						line-height: 1.125rem;
					}
				}
				.p-datatable-tbody {
					tr {
						&:last-child {
							td {
								border-bottom: 0;
							}
						}
					}
				}
				.p-datatable-tfoot {
					tr {
						td {
							background-color: $white;
							padding-left: 0;
							border-bottom: 0;
							padding-bottom: 0;
							padding-top: 0;

							a {
								color: var(--b400);
								font-weight: $bold;
								font-size: 1rem;
								line-height: 1rem;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
		.time-tracking-habit {
			.habit {
				@media (min-width: 40rem) {
					display: flex;
          justify-content: space-between;
				}
			}
			.percentage {
				@media (min-width: 40rem) {
					margin-right: 1.5rem;
				}
				p {
					color: var(--n500);
					font-size: 2.5rem;
					line-height: 3rem;

          sup {
            vertical-align:text-bottom;
            font-size: 1.25rem;
            line-height: 1.25rem;
          }
					small {
						display: block;
						color: var(--n200);
						font-size: 1rem;
						line-height: 1.5rem;
					}
				}
			}
			.days-missed {
				background: var(--n20);
				border-radius: 0.5rem;
				padding: 1rem;

        @media (min-width: 40rem) {
          width: 10.75rem;
        }
				p {
					font-size: 0.8125rem;
					line-height: 1.125rem;
					color: var(--n200);
				}
				ul {
					list-style: disc;
					padding-left: 1.25rem;
					margin-bottom: 0;

					li {
						font-weight: $bold;
						color: var(--n200);
						list-style: disc;
						font-size: 0.8125rem;
						line-height: 1.125rem;
					}
				}
			}
			.coming-soon {
				border-top: 0.0625rem solid var(--n40);
				padding: 1.25rem 1rem 0;
				margin: 1.5625rem -1rem 0;

				@media (min-width: 40rem) {
					margin-left: -1.875rem;
					margin-right: -1.875rem;
					padding-left: 1.875rem;
					padding-right: 1.875rem;
				}

				label {
					background: var(--b20);
					padding: 0.1875rem 0.25rem;
					color: var(--b400);
					font-size: 0.875rem;
					line-height: 0.875rem;
					text-align: center;
					font-weight: $regular;
					border-radius: 0.25rem;
					margin-bottom: 0.5rem;
				}
				p {
					font-size: 0.8125rem;
					line-height: 1.125rem;
					color: var(--n200);
					margin-bottom: 0;
				}
			}
		}
	}
}
.tooltip-overlaypanel {
	padding-top: 0.25rem !important;
	border-top: 0.3125rem solid var(--b400);
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
  pointer-events: none;

	&:before {
		display: none;
	}
	&:after {
		top: 50% !important;
		transform: translateY(-50%);
		border-style: solid;
		border-color: #fff;
		-moz-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		left: -0 !important;
	}
  .custom-tooltip {
    box-shadow: none;
  }
}
.custom-tooltip {
	display: flex;
	box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);
	background: var(--white);

  + .custom-tooltip {
    margin-top: 1rem;
  }

	&:last-child {
		margin-bottom: 0;
	}
	img {
		margin-right: 0.5rem;
    width: 1.5rem;
	}
	.tooltip-desc {
		h6 {
			font-weight: $medium;
			font-size: 1rem;
			line-height: 1.5rem;
			color: var(--n500);
			text-transform: none;
      margin-bottom: 0;

			small {
				display: block;
				font-weight: $regular;
				font-size: .6875rem;
				line-height: .9625rem;
				color: var(--n200);
			}
		}
	}

	&.chart-tooltip {
		border-top: 0.3125rem solid var(--n40);
		width: 12.5rem;
		background: $white;
		padding: 1.0625rem 0.75rem 1.5rem;
		z-index: 10;
		border-radius: 0.25rem;
		position: relative;
    margin:0;

		&:before {
			border-top: 0.625rem solid transparent;
			border-right: 0.625rem solid $white;
			border-bottom: 0.625rem solid transparent;
			border-left: 0.625rem solid transparent;
			position: absolute;
			top: 50%;
			left: -1.1875rem;
			transform: translateY(-50%);
			content: "";
			display: inline-block;
		}
	}
}

.work-pattern {
	.tabbed {
		display: flex;
		justify-content: center;

		.label {
			padding: 0.4375rem 0.75rem;
			background: var(--b10);
			border-radius: 2.625rem;
			font-size: 0.75rem;
			line-height: 0.75rem;
			color: var(--n60);
			font-weight: $bold;
			margin-bottom: 0;
			font-feature-settings: "tnum" on, "lnum" on, "ss01" on, "ss02" on;
			margin-right: 0.5rem;

			&:last-child {
				margin-right: 0;
			}

			&.active {
				background: var(--b400);
				color: $white;
			}
		}
	}
	.chart {
		position: relative;

		.chart-desc {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;

			p {
				font-size: 1.75rem;
				line-height: 1.75rem;
				text-align: center;
				color: var(--n600);
				font-feature-settings: "tnum" on, "lnum" on, "ss01" on, "ss02" on;
				margin-bottom: 0;

				small {
					display: block;
					font-size: 0.8125rem;
					line-height: 1.125rem;
					color: var(--n200);
				}
			}
		}
	}
	.chart-legend {
		display: flex;
		justify-content: center;

		.focus-hour {
			margin-right: 1.625rem;

			.bullet {
				background: var(--b400);
			}
		}
		.other-task {
			.bullet {
				background: var(--n60);
			}
		}
    .muted {
      opacity: 0.4;
    }
		.bullet {
			width: 0.375rem;
			height: 0.375rem;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;
		}
		h6 {
			font-weight: $medium;
			color: var(--n500);
			font-size: 1.25rem;
			line-height: 1.5rem;
			font-feature-settings: "tnum" on, "lnum" on, "ss01" on, "ss02" on;
			text-transform: none;

			small {
				font-size: 0.8125rem;
				line-height: 1.125rem;
				color: var(--n200);
				font-weight: $regular;
				display: block;

				span {
					margin-left: 0.1875rem;
				}
			}
		}
	}
	.card-footer {
		border-top: 0.0625rem solid var(--n40);
		padding: 1.25rem 1rem 0;
		margin: 1.5625rem -1rem 0;

		@media (min-width: 40rem) {
			margin-left: -1.875rem;
			margin-right: -1.875rem;
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		p {
			font-size: 1rem;
			line-height: 1.5rem;
			color: var(--n500);
			margin-bottom: 0;

			img {
				display: inline-block;
				margin-right: 0.3125rem;
				vertical-align: sub;
			}

			strong {
				font-weight: $bold;
			}
		}
	}
}
.focus-hour-tooltip {
	max-width: 18.75rem;
	margin: 0 auto;

	p {
		color: var(--n200);
		font-weight: $regular;
		font-size: 0.8125rem;
		line-height: 1.125rem;

		strong {
			font-weight: $bold;
		}
	}
}

.default-card {
	background: $white;
	box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
	text-align: center;
	padding: 2.5rem;
	margin-top: 2.5rem;

	@media (min-width: 48rem) {
		max-width: 43.625rem;
		margin: 2.5rem auto 0;
	}

	img {
		margin: 0 auto 1.25rem;
	}
	h6 {
		color: var(--n600);
		font-weight: $medium;
		font-size: 1.25rem;
		line-height: 1.625rem;
		margin-bottom: 0.25rem;
		text-transform: none;
	}
	p {
		color: var(--n200);
		font-size: 1rem;
		line-height: 1.5rem;
	}
	.btn-sd {
		height: 2.5rem;
		line-height: 2.5rem;
		font-size: 1rem;
		font-weight: $bold;
	}
}

@keyframes load {
	0% {
		width: 0;
	}
}
