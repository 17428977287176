.sd-dd {
  padding: 0;

  .p-menu-list {
    .p-menu-separator {
      margin: 0;
    }
    .red-text {
      .p-menuitem-text {
        color: var(--o200);
      }
    }
    .p-menuitem {
      a {
        color: var(--n500);

        &:hover {
          background: none;

          .p-menuitem-text {
            color: var(--b400);
          }
        }
      }
      p {
        margin: 0;

        font-size: 0.8125rem;
        line-height: 1.125rem;
        color: var(--n200);
      }
    }
  }
  .p-dropdown-items {
    .p-dropdown-item {
      padding: 0.3125rem 0.5rem;
      line-height: normal;
      text-align: left;

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            background: var(--b10);
          }
        }
      }
      &.p-highlight {
        color: var(--b400);
        background: var(--b10);

        span {
          color: var(--b400);
        }
      }
      &:hover {
        .p-menuitem-text {
          color: var(--b400);
        }
      }
      span {
        margin: 0;
        font-size: 0.8125rem;
        line-height: 1.125rem;
        color: var(--n500);
      }
    }
  }
}
.add-new {
  min-width: 13.125rem;
}
.op-sd {
  width: 15rem;
  max-height: 9.375rem;
  overflow: auto;
  box-shadow: 0 0.5rem 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;

  &:before,
  &:after {
    display: none;
  }
  .p-overlaypanel-content {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .user-block {
    width: 100%;
    margin-top: 7px;

    &:first-child {
      margin-top: 0;
    }
    .sd-avatar-xl {
      width: 4.125rem;
      height: 4.125rem;
      display: block;
    }
    p {
      margin: 0;
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .user-name {
      flex: 1 1 auto;
      margin: 0 0.5rem;
    }
  }
  .btn-avatar {
    padding: 0.125rem;
    line-height: normal;
    border-width: 0.0625rem;
    width: 1.625rem;
    height: 1.625rem;
  }
  .sd-avatar {
    max-width: 1.25rem;
  }
}
.sd-select {
  .ng-select-container {
    border: 0 none;
    font-weight: bold;
    height: 1.5rem;
    min-height: 1.5rem;
    width: 100%;
    display: inline-flex;

    &:hover {
      box-shadow: none;     
    }
    .ng-input {
      padding-left: 0;

      input {
        height: auto;
      }
    }
  }
  .ng-dropdown-panel {
    border-radius: 0.5rem;
    border:0 none;
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.16);
    min-width: 15.625rem;

    .ng-dropdown-panel-items {
      .ng-option {
        &.ng-option-selected {
          background: var(--n0);
          color: var(--b400);
          font-weight: normal;
          
          &.ng-option-marked {
            background: var(--n0);

            .ng-option-label {
              font-weight: normal;
            }
          }
          .ng-option-label {
            font-weight: normal;
          }
        }
        &.ng-option-marked {
          background: none;
          color: var(--b400);
        }
      }
      .ng-optgroup {
        font-weight: $bold;
        color: var(--n500);
      }
    }
  }
  .sd-input {
    padding: .3125rem;
    border-radius: 0;
    border: 0 none;
    height: auto;
  }
}
.custom-dropdown-footer {
  padding: .3125rem .4375rem;
  border-top: .0625rem solid #ccc;
}
.create-input {
  position:relative;
  
  .p-inputtext {
    border: .0625rem solid var(--n60);
    padding: .4375rem 1.875rem .4375rem .75rem;
    border-radius: .25rem;
    font-size: .8125rem;
    line-height: normal;
    font-weight: $regular;
    height: auto;
  }
  .btn-close {
    box-shadow: none;
    position:absolute;
    top:0;
    right:0;
    border:0 none;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
}