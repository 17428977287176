//Login
#Login {
  background-color: var(--n0);

  .bg {
    overflow:hidden;

    @media (min-width: $width-md) {
      overflow: visible;
    }
    img {
      height: 18.75rem;
      width: auto;

      @media (min-width: $width-md) {
        height: auto;
        width: 100%;
      }
    }
  }
  .block {
    position: relative;
    z-index: 1;

    &-side {
      position: absolute;
      text-align: right;
      right: 0.625rem;
      top: 0.625rem;

      @media (min-width: $width-md) {
        top: 2.6875rem;
        right: 4.1875rem;
      }

      h6 {
        color: var(--n0);
        margin-bottom: 0;
        padding-bottom: 0.5rem;
        letter-spacing: 0.0625rem;
      }
      a {
        font-weight: $bold;
      }
    }
    &-title {
      padding: 1.5rem .9375rem 1.5rem;

      @media (min-width: $width-md) {
        padding: 3rem .9375rem 2.5rem;
        text-align: center;
      }
      img {
        height: 2rem;
        display: inline-block;
      }
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 100%;
      box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.2);
      margin: 0 auto;
      padding: 2.5rem 0 4rem 0;
      border-radius: 0.25rem;

      @media (min-width: $width-md) {
        width: 43.4375rem;
        background: var(--n0);
      }
      &__header {
        border-bottom: 0.0625rem solid var(--n40);
        width: 100%;
        text-align: center;
        padding-bottom: 2.5rem;
        padding-left: .9375rem;
        padding-right: .9375rem;

        &.no-border {
          border: 0 none;
          padding-bottom: 0;

          + .alert {
            margin-top: 2.5rem;
          }
        }
        h1 {
          margin-bottom: 0.5rem;
          line-height: 3rem;
        }
        p {
          margin: 0;
        }
      }
      .login-form {
        margin-top: 2.5rem;
        width: 100%;
        padding: 0 1.25rem;

        @media (min-width: $width-sm) {
          padding: 0 3.75rem;
        }
        @media (min-width: $width-md) {
          padding: 0 7.5rem;
        }
        label {
          font-weight: $medium;
          position: relative;
          margin-bottom: 0.5rem;
          font-size: 1rem;
          display: block;

          a {
            position: absolute;
            right: 0;
            bottom: 0;
            font-weight: $regular;
            font-size: 0.8125rem;
          }
        }
        .p-field-checkbox {
          label {
            margin-bottom:0;
          }
        }
        &-footer {
          p {
            margin-bottom: 0;
            font-weight: $regular;
          }
        }
      }
      .error {
        margin-bottom: 1rem;
      }
      .helper {
        font-size: 0.8125rem;
        margin-bottom: 2.1875rem;
        color: var(--n200);
        display: block;
        margin-top: -0.4375rem;
      }
      button {
        margin: 0;
        display: block;
      }
    }
  }
  &.upgrade-plan {
    .block {
      &-content {
        &__header {
          h1 {
            margin:0;
          }
          &.box-success {
            padding: 0 1.875rem;
            margin-bottom: -1.5rem;

            @media (min-width: $width-sm) {
              padding: 0 3.75rem;
            }
            h1{
              font-size: 1.75rem;
              margin-bottom: 1.5rem;

              @media (min-width: $width-sm) {
                font-size: 2.5rem;
              }
            }
            p {
              margin-bottom: 1rem;
            }
          }
        }
        .login-form {
          margin-top: 1.875rem;

          h3 {
            margin-top: 2.5rem;
            margin-bottom: 1.25rem;
          }
          .billing-period {
            margin-bottom: 0;

            label {
              margin-bottom: 1rem;
            }
            h4 {
              font-size: 1.5rem;
              font-weight: $regular;
              line-height: 1.8125rem;
              margin-bottom: 0;
            }
          }
          .billing-options {
            display:inline-flex;
            margin-bottom: 1rem;

            label {
              margin-bottom:0;
              font-weight: $regular;

              &.active {
                color: var(--b400);
                font-weight: $bold;
              }
            }
            .p-inputswitch {
              margin: 0 1.25rem;
            }
          }
          .card-container {
            height: 3rem;
            width: 100%;
            font-size: .875rem;
            padding: 1rem 1rem;
            border: .0625rem solid #d5d5d8;
            border-radius: .25rem;
            font-family: $Larsseit;
          }
          .p-dropdown {
            width: 100%;
            color: #42414f;
            height: 3rem;

            &-label {
              font-size: 1rem;
              padding: .75rem 1rem;

              .card-option {
                span {
                  vertical-align: top;

                  i {
                    display:inline-block;
                    height: auto;
                    width: auto;
                    font-size:0;

                    &:before {
                      line-height: auto;
                      width: auto;
                      height: auto;
                      font-size: 1.5rem;
                    }
                  }
                }
              }
            }
          }
          .btn-block {
            margin-top: 1.5rem;
            font-size: 1.25rem;
            font-weight: $medium;
          }
        }
      }
    }
  }
}
