.sd-table {
	box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
	background: var(--n0);

	.p-datatable-thead {
		tr {
			th {
				background: var(--b10);
				color: var(--b200);
				font-size: 0.875rem;
				border: 0 none;

				.p-sortable-column-icon {
					font-size: 0.5rem;
					color: var(--b200);
				}
				.pi-sort-amount-up-alt {
					&:before {
						content: "\e919";
					}
				}
				.pi-sort-amount-down {
					&:before {
						content: "\e91c";
					}
				}
				&:first-child {
					border-top-left-radius: 0.5rem;
				}
				&:last-child {
					border-top-right-radius: 0.5rem;
				}
				&.p-sortable-column {
					background: var(--b10);
					color: var(--b200);
					&:hover,
					&:not(.p-highlight) {
						background: var(--b10);
						color: var(--b200);

						&:hover {
							.p-sortable-column-icon {
								color: var(--b200);
							}
						}
					}
					&.p-highlight {
						.p-sortable-column-icon {
							color: var(--b200);
						}
					}
				}
			}
		}
	}
	.p-datatable-tbody {
		> tr {
			> td {
				border: 0 none;
				border-bottom: 0.0625rem solid var(--n40);

				.btn-dd {
					color: var(--n100);

					&:hover {
						color: var(--n500) !important;
					}
				}
				&.col-menu {
					@media (min-width: $width-sm) {
						text-align: right;
						width: 8.125rem;
					}
				}
			}
		}
	}
	@media (max-width: $max-width-sm) {
		&.table-responsive {
			.p-datatable-tfoot {
				> tr {
					> td {
						display: none !important;
					}
				}
			}
			.p-datatable-thead {
				> tr {
					> th {
						display: none !important;
					}
				}
			}
			.p-datatable-tbody {
				> tr {
					display: block;
					position: relative;
					border-top: 0.0625rem solid var(--n40);
					padding-bottom: 1rem;

					&:first-child {
						border-top: 0;
					}
					> td {
						border: 0;
						display: block;
						width: 100%;
						padding-bottom: 0;

						.p-column-title {
							display: block;
							font-weight: $bold;
							margin-bottom: 0.25rem;
							font-size: 0.875rem;
						}
						&:first-child {
							padding-right: 2rem;
						}
						&.col-menu {
							position: absolute;
							top: 0.5rem;
							right: 0;
							width: auto;
							padding: 0;
						}
					}
				}
			}
		}
	}
	&.table-people {
		tr {
			&:not(.deactivated) {
				&:hover {
					td {
						.user {
							h6 {
								color: var(--b400);
							}
						}
						.btn-dd {
							color: var(--n500);
						}
					}
				}
			}
			&.deactivated {
				background: var(--n20);

				td {
					color: var(--n100);

					.user {
						&-img {
							opacity: 0.5;
						}
						h6 {
							color: var(--n100);
						}
					}
					.btn-dd {
						&:hover {
							color: var(--n100) !important;
						}
					}
				}
			}
			td {
				font-size: 0.8125rem;

				&.team_admin {
					font-weight: $bold;
					color: var(--g300);
				}
				&:last-child {
					width: 1.875rem;
				}
				.p-column-title {
					display: none;
				}
				.user {
					&-img {
						width: 1.5rem;
						border-radius: 50%;
						padding-top: 0.1875rem;
						margin-right: 0.75rem;

						img {
							width: 1.5rem;
							height: 1.5rem;
							border-radius: 50%;
						}
						.p-avatar {
							width: 1.5rem;
							height: 1.5rem;
							font-size: 0.625rem;
							font-weight: $bold;
						}
					}
					h6 {
						color: var(--n600);
						text-transform: none;
						margin: 0;
						font-weight: $medium;

						small {
							display: block;
							font-weight: $regular;
							font-size: 0.6875rem;
							color: var(--n200);
						}
					}
				}
			}
		}
	}
	&.table-project {
		box-shadow: none;

		.p-datatable-thead {
			tr {
				th {
					font-size: 0.875rem;
					color: var(--b200);
					font-weight: bold;
					background: var(--n20);
					border-radius: 0;
					border-bottom: 0.0625rem solid var(--b50);

					&:first-child {
						padding-left: 0;
					}
				}
			}
		}
		.p-datatable-tbody {
			> .client-row {
				background: var(--n20);
				box-shadow: none;
				padding: 0;

				@media (min-width: $width-md) {
					padding: 0;
				}
				&:first-child {
					td {
						padding-top: 1.125rem !important;
					}
				}
				td {
					padding: 2rem 0 1rem 0 !important;
					border: 0 none !important;

					h3 {
						font-weight: $regular;
						vertical-align: middle;
						margin: 0;

						@media (min-width: $width-md) {
							order: 0;
						}

						a {
							color: var(--n100);
							font-size: 0.75rem;
							margin-left: 0.625rem;
							cursor: pointer;

							.pi {
								font-size: 0.75rem;
							}
						}
					}
					.project-badge {
						width: 100%;
						align-self: center;

						@media (min-width: $width-md) {
							order: 1;
							width: auto;
							margin-left: 1rem;
						}
						.badge {
							margin-right: 0.375rem;
							display: inline-block;
							font-size: 0;
						}
					}
				}
				&.archived-company {
					td {
						h3 {
							color: var(--n100);
						}
					}
				}
				+ tr {
					td {
						@media (min-width: $width-md) {
							border-top: 0.0625rem solid var(--n40);
						}
						&:first-child {
							@media (min-width: $width-md) {
								border-left: 0.0625rem solid var(--n40);
							}
						}
						&:last-child {
							@media (min-width: $width-md) {
								border-right: 0.0625rem solid var(--n40);
							}
						}
					}
				}
				+ .isHighlighted {
					border-top: 0.125rem solid var(--y60);

					@media (min-width: $width-md) {
						border-top: 0 none;
					}
					td {
						@media (min-width: $width-md) {
							border-top: 0.125rem solid var(--y60);
						}
						&:first-child {
							@media (min-width: $width-md) {
								border-left: 0.125rem solid var(--y60);
							}
						}
						&:last-child {
							@media (min-width: $width-md) {
								border-right: 0.125rem solid var(--y60);
							}
						}
					}
				}
			}
			> tr {
				box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
				padding-bottom: 1rem;

				@media (min-width: $width-md) {
					padding-bottom: 0;
					box-shadow: none;
				}
				&.isArchived {
					td {
						.project-name,
						.project-status,
						.project-tracked,
						.project-user {
							color: var(--n100);
						}
						.project-avatar {
							opacity: 0.2;
						}
					}
				}
				&.isHighlighted {
					border-left: 0.125rem solid var(--y60);
					border-right: 0.125rem solid var(--y60);

					@media (min-width: $width-md) {
						border: 0 none;
					}
					&:last-child {
						border-bottom: 0.125rem solid var(--y60);
					}
					+ .client-row {
						td {
							border-top: 0.125rem solid var(--y60) !important;
						}
					}
					td {
						&:first-child {
							@media (min-width: $width-md) {
								border-left: 0.125rem solid var(--y60);
							}
						}
						&:last-child {
							@media (min-width: $width-md) {
								border-right: 0.125rem solid var(--y60);
							}
						}
					}
					&:last-child {
						td {
							@media (min-width: $width-md) {
								border-bottom: 0.125rem solid var(--y60);
							}
						}
					}
				}
			}
			td {
				vertical-align: top;
				border-top: 0.0625rem solid var(--n40);
				border-bottom: 0.0625rem solid var(--n40);

				&:first-child {
					@media (min-width: $width-md) {
						border-left: 0.0625rem solid var(--n40);
					}
				}
				&:last-child {
					@media (min-width: $width-md) {
						border-right: 0.0625rem solid var(--n40);
					}
				}
				.project-name,
				.project-status,
				.project-tracked,
				.project-user {
					display: block;
					font-size: 1rem;
				}
				.project-name {
					font-weight: $bold;
					color: var(--n600);

					i {
						margin-left: 0.25rem;
						color: var(--n200);
					}
				}
				.project-user {
					white-space: nowrap;
				}
				small {
					font-size: 0.6875rem;
					line-height: 0.9625rem;
					color: var(--n200);
					display: block;
				}
				a {
					cursor: pointer;
					font-size: 0.6875rem;
				}
				.project-avatar {
					margin-top: 0.25rem;
					display: inline-flex;
					align-content: center;

					@media (min-width: $width-md) {
						margin-top: 0;
					}
					> * {
						flex: 1 0 auto;
					}

					img,
					.sd-avatar {
						margin-right: 6px;
						width: 1.5rem;
						height: 1.5rem;
						border-radius: 50%;
						font-size: 0.75rem;
						font-weight: $bold;

						&.group {
							background: var(--b20);
							color: var(--b400);
						}
					}
				}
				.project-status-container {
					position: relative;
					padding-left: 0.75rem;

					&:before {
						content: "";
						width: 0.375rem;
						height: 0.375rem;
						border-radius: 50%;
						position: absolute;
						top: 0.5625rem;
						left: 0;
						background: var(--g200);
					}
					&.archived {
						&:before {
							background: var(--n60);
						}
					}
					&.dormant {
						&:before {
							background: var(--lb60);
						}
					}
				}
				.project-collaborator {
					flex: 0 0 6.25rem;
				}
				&.col-menu {
					vertical-align: middle;
				}
			}
		}
	}
	&.table-tasks {
		box-shadow: none;
		margin-bottom: 1.5rem;

		.p-datatable-tbody {
			> tr {
				> td {
					padding: 0.875rem;

					&.text-right {
						text-align: right;
					}
					&:first-child {
						padding-left: 0;
						padding-right: 0;

						p {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					&:nth-child(2) {
						width: 6.5625rem;
					}
					&:last-child {
						padding-right: 0;
						width: 1.5625rem;
						padding-left: 0;
					}
					p {
						margin: 0;
						vertical-align: middle;
					}
					.task-color {
						width: 0.375rem;
						height: 0.375rem;
						margin-right: 0.375rem;
						border-radius: 50%;
						display: inline-block;
						vertical-align: middle;
						margin-top: -0.125rem;
					}
				}
			}
		}
		tr {
			box-shadow: none;
		}
	}
	&.table-entry {
		box-shadow: none;

		@media (min-width: $width-md) {
			margin-top: 0.625rem;
		}
		@media (min-width: $width-lg) {
			margin-top: 2.5rem;
		}
		.p-datatable-thead {
			tr {
				th {
					background: none;
					font-size: 0.625rem;
					font-weight: $medium;
					padding: 0 0.75rem;

					@media (min-width: $width-lg) {
						&.col1 {
							width: 35%;
							padding-left: 0;
						}
						&.col2,
						&.col3 {
							width: 5.625rem;
						}
						&.col4 {
							text-align: center;
							width: 4.0625rem;
						}
						&.col5 {
							width: 13.75rem;
							max-width: 13.75rem;
						}
						&.col6 {
							width: 4.375rem;
							text-align: right;
							padding-right: 0;
							padding-left: 0;
						}
						&.col7 {
							width: 5rem;
							padding-right: 0;
						}
					}
					@media (min-width: $width-xl) {
						&.col1 {
							width: 40%;
						}
						&.col2,
						&.col3 {
							width: 9.375rem;
						}
					}
					@media (min-width: $width-xxl) {
						&.col2,
						&.col3 {
							width: 12.5rem;
						}
					}
				}
			}
		}
		.p-datatable-tbody {
			> tr {
				background: none;

				@media (min-width: $width-lg) {
					border-radius: 0.125rem;
				}
				&:hover {
					@media (min-width: $width-lg) {
						background: var(--b10);

						.col-menu {
							.block-hover {
								display: block;
							}
						}
					}
				}
				.col-menu {
					.force-show {
						&.block-hover {
							display: block;
						}
					}
				}
				&.row-edit {
					.block-hover {
						display: none;
					}
					@media (min-width: $width-lg) {
						box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);

						&:hover {
							background: none;

							.block-hover {
								display: none;
							}
						}
						.col-menu {
							.block-edit {
								display: block;
							}
						}
					}
				}
				&.row-clean {
					> td {
						color: var(--n100);

						&.col1 {
							.column-container {
								display: inline-block;
								vertical-align: middle;
								width: 100%;

								&.col-task {
									display: inline-flex;
									max-width: 100%;
									align-items: flex-start;
								}
							}
							.overlap-indicator {
								position: relative;

								&:after {
									position: absolute;
									right: -6px;
									top: 0;
									content: "";
									display: inline-block;
									width: 6px;
									height: 6px;
									border-radius: 50%;
									background-color: var(--o200);
									border: 3px solid $white;
								}
							}
							.calendar-icon {
								display: inline-block;
								margin-left: 0.375rem;
								vertical-align: middle;
							}
						}
					}
				}
				> td {
					border-bottom: 1px solid var(--n40);
					color: var(--n500);
					padding: 0.625rem;
					text-align: left;
					font-size: 0.8125rem;
					vertical-align: middle;

					> div {
						&:not(.block-action) {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
					&.col6 {
						font-size: 1rem;

						> div {
							&:not(.block-action) {
								white-space: nowrap;
								text-overflow: normal;
								overflow: visible;
							}
						}
					}
					p {
						margin: 0;
					}
					@media (min-width: $width-xs) and (max-width: $max-width-md) {
						&.col2,
						&.col3 {
							width: 50%;
							display: inline-block;
						}
						&.col2 {
							padding-right: 0.625rem;
						}
						&.col3 {
							padding-left: 0.625rem;
						}
						&.col4 {
							position: absolute;
							bottom: 1rem;
							right: 0;
							width: auto;
							padding-top: 0;
							text-align: center;
						}
						&.col5 {
							width: 50%;
							display: inline-block;
							padding-right: 0.625rem;
						}
						&.col6 {
							width: 50%;
							display: inline-block;
							padding-left: 0.625rem;
							padding-right: 3.75rem;
						}
					}
					@media (min-width: $width-lg) {
						padding-left: 0.75rem;
						padding-right: 0.75rem;

						&.col1 {
							padding-left: 0.3125rem;
						}
						&.col4 {
							text-align: center;
						}
						&.col5 {
							white-space: nowrap;
						}
						&.col6 {
							text-align: right;
							padding-left: 0;
							padding-right: 0;
						}
						&.col7 {
							padding-right: 0;
							white-space: nowrap;
						}
						p {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.p-column-title {
						display: none;
					}
					.column-container {
						padding-left: 0.75rem;
						position: relative;
						font-size: 1rem;

						.task-color {
							position: absolute;
							left: 0;
							top: 0.625rem;
						}
						.p-inputtext {
							font-size: 1rem;
						}
						p {
							white-space: normal;

							@media (min-width: $width-lg) {
								white-space: nowrap;
							}
						}
						&.col-task {
							display: inline-flex;
							max-width: 100%;
              width: 100%;

							img {
								margin-top: 0.3125rem;
							}
						}
					}
					.p-inputtext {
						padding: 0;
						height: 1.5rem;
						font-size: 1rem;
						border: 0 none;
						font-weight: bold;
						font-size: 0.8125rem;

						&:focus {
							box-shadow: none;
						}
					}
					.ng-select-container {
						border: 0 none;
						font-weight: bold;
						height: 1.5rem;
						min-height: 1.5rem;
						width: 100%;
						display: inline-flex;

						.ng-input {
							padding-left: 0;

							input {
								height: auto;
							}
						}
					}
					.ng-value-container {
						padding-left: 0;
					}
					.ng-dropdown-panel {
						min-width: 15.625rem;
					}
					.task-color {
						width: 0.375rem;
						height: 0.375rem;
						margin-right: 0.375rem;
						border-radius: 50%;
						margin-top: -0.125rem;
					}
					.button-close {
						color: var(--n100);
						font-size: 0.75rem;
					}
					.time-wrapper {
						color: var(--n100);

						i {
							font-size: 0.5rem;
							color: var(--n100);
							margin: 0;
							vertical-align: middle;

							&:before {
								width: auto;
								margin: 0;
							}
						}
						.p-inputtext {
							flex: 0 0 auto;
							width: 4.6875rem;
						}
						.start-date,
						.end-date {
							width: 3.75rem;
							padding: 0;
							text-align: center;
						}
						.p-calendar {
							.p-datepicker-trigger {
								order: 0;
								border: 0 none;
								background: none;
								color: var(--g300);
								padding: 0;
								width: auto;
								border-radius: 0;
								margin-right: 0.125rem;
								box-shadow: none;
								line-height: auto;
								height: 1.25rem;
							}
							.p-inputtext {
								order: 1;
								width: 2.8125rem;
								color: var(--g300);
								padding: 0;
							}
						}
					}
					.p-togglebutton {
						background: none;

						span {
							color: var(--n100) !important;
						}
						&:hover {
							span {
								color: var(--g300) !important;
							}
						}
					}
					&.col-menu {
						width: 6.25rem;
						text-align: right;

						.p-button {
							width: auto;
							height: auto;
							padding: 0 0.625rem;

							&:hover {
								background: none;
							}
						}
						@media (min-width: $width-lg) {
							.block-action {
								display: none;
							}
						}
					}
				}
				&:last-child {
					td {
						border-bottom: 0;
					}
				}
			}
		}
		@media (max-width: $max-width-md) {
			.p-datatable-tfoot {
				> tr {
					> td {
						display: none !important;
					}
				}
			}
			.p-datatable-thead {
				> tr {
					> th {
						display: none !important;
					}
				}
			}
			.p-datatable-tbody {
				> tr {
					display: block;
					position: relative;
					border-top: 0.0625rem solid var(--n40);
					padding-bottom: 1rem;
					padding-top: 0.625rem;

					&:first-child {
						border-top: 0;
					}
					> td {
						border: 0;
						display: block;
						width: 100%;
						padding-bottom: 0;
						padding-left: 0;
						padding-right: 0;

						.p-column-title {
							display: block;
							font-weight: $bold;
							margin-bottom: 0.25rem;
							font-size: 0.875rem;
						}
						&:first-child {
							padding-right: 0;
						}
						&.col-menu {
							position: absolute;
							top: 0.625rem;
							right: -1rem;
							width: auto;
							padding: 0;
						}
					}
				}
			}
		}
	}
	&.table-history {
		box-shadow: none;

		@media (min-width: $width-md) {
			margin-top: 1.875rem;
		}
		@media (min-width: $width-lg) {
			margin-top: 3.75rem;
		}
		.p-datatable-thead {
			tr {
				th {
					background: none;
					font-size: 0.625rem;
					font-weight: $medium;
					padding: 0 0.75rem;

					@media (min-width: $width-lg) {
						padding-left: 0.5rem;
						padding-right: 0.5rem;
						border-bottom: 0.0625rem solid var(--b50);
						padding-bottom: 0.8125rem;

						&.col0 {
							width: 40px;
						}
						&.col1 {
							width: 35%;
							padding-left: 0;
						}
						&.col2,
						&.col3,
						&.col4 {
							width: 6.25rem;
						}
						&.col5 {
							text-align: center;
							width: 4.0625rem;
						}
						&.col6 {
							width: 13.75rem;
							max-width: 13.75rem;
						}
						&.col7 {
							width: 5rem;
							text-align: right;
							max-width: 6.25rem;
						}
						&.col8 {
							width: 4.375rem;
							padding-right: 0;
						}
					}
					@media (min-width: $width-xl) {
						&.col1 {
							width: 40%;
						}
						&.col2,
						&.col3 {
							width: 11.25rem;
						}
						&.col4 {
							width: 8.125rem;
						}
					}
				}
			}
		}
		.p-datatable-tbody {
			> tr {
				background: none;

				@media (min-width: $width-lg) {
					border-radius: 0.125rem;
				}
				&:hover {
					@media (min-width: $width-lg) {
						background: var(--b10);

						.col-menu {
							.block-hover {
								display: block;
							}
						}
					}
				}
				&.row-edit {
					@media (min-width: $width-lg) {
						box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);

						&:hover {
							background: none;

							.block-hover {
								display: none;
							}
						}
						.col-menu {
							.block-edit {
								display: block;
								margin-left: -0.625rem;
							}
						}
					}
				}
				> td {
					border-bottom: 1px solid var(--n40);
					color: var(--n500);
					padding: 0.625rem;
					text-align: left;
					font-size: 0.8125rem;
					vertical-align: middle;

					> div {
						&:not(.block-action) {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}

					&.col7 {
						font-size: 1rem;
					}
					p {
						margin: 0;
					}
					@media (min-width: $width-xs) and (max-width: $max-width-md) {
						&.col2,
						&.col3 {
							width: 50%;
							display: inline-block;
						}
						&.col2 {
							padding-right: 0.625rem;
						}
						&.col3 {
							padding-left: 0.625rem;
						}
						&.col5 {
							position: absolute;
							bottom: 1rem;
							right: 0;
							width: auto;
							padding-top: 0;
							text-align: center;
						}
						&.col6 {
							width: 50%;
							display: inline-block;
							padding-right: 0.625rem;
						}
						&.col7 {
							width: 50%;
							display: inline-block;
							padding-left: 0.625rem;
							padding-right: 3.75rem;
						}
					}
					@media (min-width: $width-lg) {
						padding-left: 0.5rem;
						padding-right: 0.5rem;

						&.col1 {
							padding-left: 0;
						}
						&.col5 {
							text-align: center;
						}
						&.col6 {
							white-space: nowrap;
						}
						&.col7 {
							text-align: right;
						}
						&.col8 {
							padding-right: 0;
							white-space: nowrap;
						}
						p {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.p-column-title {
						display: none;
					}
					.column-container {
						position: relative;
						font-size: 1rem;

						.task-color {
							position: absolute;
							left: 0;
							top: 0.625rem;
						}
						.p-inputtext {
							font-size: 1rem;
						}
						p {
							white-space: normal;

							@media (min-width: $width-lg) {
								white-space: nowrap;
							}
						}
					}
					.p-inputtext {
						padding: 0 0.3125rem;
						height: 2.25rem;
						font-size: 1rem;
						border: 0 none;
						font-weight: bold;
						font-size: 0.8125rem;
					}
					.ng-select-container {
						border: 0 none;
						font-weight: bold;

						.ng-input {
							padding-left: 0;

							input {
								height: 2.25rem;
							}
						}
					}
					.ng-value-container {
						padding-left: 0;
					}
					.ng-dropdown-panel {
						min-width: 15.625rem;
					}
					.task-color {
						width: 0.375rem;
						height: 0.375rem;
						margin-right: 0.375rem;
						border-radius: 50%;
						margin-top: -0.125rem;
					}
					.button-close {
						color: var(--n100);
						font-size: 0.75rem;
					}
					.time-wrapper {
						color: var(--n100);

						i {
							font-size: 0.5rem;
							color: var(--n100);
							margin: 0;
							vertical-align: middle;

							&:before {
								width: auto;
								margin: 0;
							}
						}
						.p-inputtext {
							flex: 0 0 auto;
							width: 4.6875rem;
						}
						.start-date,
						.end-date {
							width: 3.75rem;
							padding: 0;
							text-align: center;
						}
						.p-calendar {
							.p-datepicker-trigger {
								order: 0;
								border: 0 none;
								background: none;
								color: var(--g300);
								padding: 0;
								width: auto;
								border-radius: 0;
								margin-right: 0.125rem;
							}
							.p-inputtext {
								order: 1;
								width: 2.8125rem;
								color: var(--g300);
								padding: 0;
							}
						}
					}
					.p-togglebutton {
						background: none;

						span {
							color: var(--n100) !important;
						}
					}
					&.col-menu {
						width: 6.25rem;
						text-align: right;

						.p-button {
							width: auto;
							height: auto;
							padding: 0 0.625rem;

							&:hover {
								background: none;
							}
						}
						@media (min-width: $width-lg) {
							.block-action {
								display: none;
							}
						}
					}
				}
			}
		}
		@media (max-width: $max-width-md) {
			.p-datatable-tfoot {
				> tr {
					> td {
						display: none !important;
					}
				}
			}
			.p-datatable-thead {
				> tr {
					> th {
						display: none !important;
					}
				}
			}
			.p-datatable-tbody {
				> tr {
					display: block;
					position: relative;
					border-bottom: 0.0625rem solid var(--n40);
					padding-bottom: 1rem;
					padding-top: 0.625rem;

					> td {
						border: 0;
						display: block;
						width: 100%;
						padding-bottom: 0;
						padding-left: 0;
						padding-right: 0;

						.p-column-title {
							display: block;
							font-weight: $bold;
							margin-bottom: 0.25rem;
							font-size: 0.875rem;
						}
						&:first-child {
							padding-right: 0;
						}
						&.col-menu {
							position: absolute;
							top: 0.625rem;
							right: -1rem;
							width: auto;
							padding: 0;
						}
					}
				}
			}
		}
	}
}
