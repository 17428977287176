.report {
  &-title {
    margin-bottom: 1rem;
  }
  &-head {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    h4 {
      font-size: 1.25rem;
      line-height: 1.4375rem;
      font-weight: $medium;
    }
    .today-duration {
      order: 1;

      @media (min-width: $width-md) {
        order: 2;
      }
      p {
        display: inline-block;
        margin: 0 1.5rem 0 0;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: $medium;
        vertical-align: middle;
      }
      .btn-icon {
        padding: 0;
        margin: 0;
        width: auto;
        color: var(--n100);
        vertical-align: middle;

        &:hover {
          color: var(--g300);
        }
      }
    }
    .today-insight {
      order: 2;
      flex: 1 1 100%;

      @media (min-width: $width-md) {
        order: 1;
        flex:1 1 auto;
        text-align: left;
        padding-left: 1rem;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin: 0;

        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.1875rem;
        }
        span {
          background: var(--y50);
          font-size: 0.8125rem;
        }
      }
    }
    p-togglebutton {
      margin-left: 1rem;

      .toggler {
        font-size: 0.5rem;
        color: var(--n500);
        width: 1.25rem;
        padding: 0;
        vertical-align: middle !important;

        span {
          color: var(--n100) !important;
        }
        &.p-highlight {
          span {
            color: var(--n500) !important;
          }
        }
        span {
          &:before {
            width: auto;
          }
        }
      }
    }
  }
  &-tracked {
    width: 100%;

    .report-avatar {
      width: 2.5rem;
      height: 2.5rem;
      margin-top: 1rem;
      margin-right: 1rem;
    }
    .report-charts {
      flex: 1 0 100%;
      width: 100%;
    }
    .chart-container {
      min-width: 12.5rem;
      max-width: 60.625rem;
      display: block;
    }
  }
  &-content {
    margin-bottom: 2rem;

    &.today-progress {
      h2 {
        font-weight: $regular;
        margin-bottom: 8px;
      }
      .report-charts {
        flex: 1 0 auto;
        width: calc(100% - 3rem);
      }
      .report-tracked {
        max-width: 65.1875rem;
        margin: 0 auto;
      }
      .today-duration {
        @media (min-width: $width-md) {
          padding-right: 2.1875rem;
        }
      }
    }
    &.timer-list {
      .table-entry-wrapper {
        display: none;

        &.shown {
          display:block;
        }
      }
      .report-avatar {
        display: none;
      }
      .report-head {
        h4 {
          margin: 0;
        }
      }
      .p-card-body {
        padding: 1.5rem;

        &:hover {
          .toggler span {
            color: var(--g300) !important;
          }
        }
      }
      .p-calendar {
        .p-inputtext {
          width: 3.125rem;
          border-radius: 0;
        }
      }
      .chart-container {
        min-width: 12.5rem;
        max-width: 56.875rem;
        position: relative;

        .highcharts-container {
          margin: 0 auto;
        }
      }
      .listingonly-view {
        .table-entry-wrapper {
          display: block;
        }
        .report-head {
          margin-bottom: .375rem;

          .today-duration {
            p-togglebutton {
              display: none;
            }
          }
        }
        .report-tracked {
          display: none !important;
        }
      }
      
      @media (min-width: $width-md) {
        .p-card-body {
          padding: 1.5rem 2rem;
          position: relative;
        }
        .report-head {
          margin-bottom: 0;
        }
        .report-tracked {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 40%;
        }
      }
      @media (min-width: $width-lg) {
        .report-tracked {
          width: 50%;
        }
      }
      @media (min-width: $width-xxl) {
        .report-tracked {
          padding-left: 0;
          padding-right: 0;
          width: 56.875rem;
        }
      }
    }
  }
  &-subtitle {
    width: 100%;
    margin-bottom: 1rem;

    h5 {
      margin-bottom: 0;
    }
  }
}
