.sd-dialog {
	&.dialog-onboarding {
		height: 100%;
		border: 0 none;
		max-height: none;
		border-radius: 0;
		max-width: 100%;

		@media (min-width: $width-md) {
			max-width: 43.75rem;
			max-height: none;
			height: auto;
			border-radius: 0.5rem;
      overflow: hidden;
		}
		@media (min-width: $width-lg) {
			max-width: 56.25rem;
		}
		.p-dialog-content {
			@media (min-width: $width-md) {
				border-radius: 0;
			}
		}
    .step1 {
      .img-block {
        @media (min-width: $width-md) {
          display: none;
        }
      }
    }
    .modal-header {
      display: none;

      @media (min-width: $width-md) {
        display: block;
        padding: 1.5rem 2.5rem;
      }
      h3 {
        @media (min-width: $width-md) {
          margin-bottom: 0;
        }
      }
      .btn-sd {
        display: none;

        &.btn-close {
          @media (min-width: $width-md) {
            display: block;
            box-shadow: none;
            position: absolute;
            right:1.75rem;
            top:1.5rem;
            color: var(--n60);
          }
        }
      }
    }
		.img-block {
			background: var(--b400);
			min-height: 30.9375rem;
			padding: 0 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (min-width: $width-md) {
        padding: 0 2.5rem 1.5rem 2.5rem;
        min-height: 0;
        background: var(--n0);
			}
			img {
				position: relative;
				border-radius: 0.5rem;

        &.mobile {
          @media (min-width: $width-md) {
            display: none;
          }
        }
        &.desktop {
          display: none;

          @media (min-width: $width-md) {
            display: block;
          }
        }
			}
		}
		.swiper-pagination {
			display: inline-flex;
			margin: 0;
			padding: 0;
			list-style: 0 none;
			justify-content: center;
      position: absolute;
      top: 31.875rem;

			@media (min-width: $width-md) {
				display: none;
			}

			.swiper-pagination-bullet {
				margin: 0 0.125rem;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				background: var(--b50);

				&-active {
					background: var(--b800);
				}
			}
		}
		.modal-text {
			border-radius: 0.5rem 0.5rem 0 0;
			background: $white;
			padding: 3.5rem 1.5rem 1.5rem 1.5rem;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			height: calc(100vh - 30.4375rem);
			text-align: center;
			margin-top: -0.5rem;

			@media (min-width: $width-md) {
				height: auto;
				margin-top: 0;
				border-radius: 0;
        text-align: left;
        padding: 0 2.5rem 1.5rem 2.5rem;
			}
      h3 {
        margin-bottom: 1rem;

        @media (min-width: $width-md) {
          display: none;
        }
      }
			.modal-content {
				flex: 1;
				p {
					margin-bottom: 0;
					color: var(--n500);

					+ p {
						margin-top: 1rem;

            @media (min-width: $width-md) {
              margin-top: 1.5rem;
            }
					}
				}
        // .p-field-checkbox {
        //   display: none;

        //   @media (min-width: $width-md) {
        //     display: block;
        //     margin-top: 2rem;
        //     margin-bottom:0;
        //   }
        //   label {
        //     @media (min-width: $width-md) {
        //       color: var(--n400);
        //       font-size: 1rem;
        //       font-weight: $regular;
        //     }
        //   }
        // }
			}
			.modal-footer {
				display: inline-flex;
				justify-content: space-between;
				align-items: center;

        @media (min-width: $width-md) {
          margin: 2.5rem -2.5rem -1.5rem -2.5rem;
          padding: 1.5rem 2.5rem;
          border-top: .0625rem solid var(--n40);
          justify-content: flex-end;
        }

				&.justify-center {
					justify-content: center;

          @media (min-width: $width-md) {
            justify-content: flex-end;
          }
				}
				.btn-skip {
					cursor: pointer;
					padding: 0 1rem;
					height: 2.5rem;
					line-height: 2.5rem;
					font-size: 1rem;
					font-weight: $bold;
					color: var(--n500);

					@media (min-width: $width-md) {
						display: none;
					}
				}
        .btn-prev {
          @media (min-width: $width-md) {
            margin-right: .5rem;
          }
        }
			}
		}
	}
}
