.history-head {
  width: 100%;

  &-title {
    h6 {
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: $regular;
      text-transform: none;
      color: var(--n500);
      margin-bottom: .5rem;
    }
    h3 {
      font-size: 1.75rem;
      line-height: 1.75rem;
      color: var(--n600);
      margin:0;
      font-weight: $regular;
    }
  }
  &-button {
    .p-button {
      margin-left: .75rem;
      font-weight: $bold;

      &-icon {
        font-size: 1rem;
      }
    }
  }
}
.chart-title {
  display: inline-flex;
}
.chart-filter {
  margin-bottom: 1rem;

  label {
    margin-right: .625rem;
    font-size: .75rem;
    font-weight: $regular;
    margin-bottom: 0;
    color: var(--n500);
  }
}
